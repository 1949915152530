<template>
  <div class="columns is-multiline">
    <modal-add-user-group
      v-if="modalAddGroup"
      :close="showModal"
      :addGroup="addGroup"
      :loadingAdd="loadingAdd"
    />
    <div class="column is-12 is-paddingless is-marginless has-text-left titleCard">
      <nav class="level">
        <div class="level-left">
          Perfil
        </div>
        <div class="level-left">
        </div>
        <div class="level-right">
          <div class="level-item">
            <button class="button is-info is-small" @click="showModal"> Asignar Grupo </button>
          </div>
          <div class="level-item" v-if="!customer.account_confirm">
            <button class="button is-link is-small" :class="{'is-loading': loading}" @click="sendEmail"> Reenviar Correo </button>
          </div>
        </div>
      </nav>
    </div>
    <div class="column is-12 has-text-centered separator">
    </div>
    <div class="column is-3 has-text-centered">
      <img :src="customer.userImage" class="userImage"/>
      <p class="calificacion">
        <template v-for="i in 5">
        <i class="material-icons selected" :key="i" v-if="i <= customer.score || 0">star</i>
        <i class="material-icons" :key="i" v-else>star_border</i>
        </template>
      </p>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline is-mobile contenedorInfo">
        <div class="column is-12 paddingDescription">
          <p class="valueElement name">{{customer.name}} {{customer.last_name}}</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement userName">{{customer.username}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Correo Electrónico</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{customer.email}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Correo Electrónico Alternativo</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{customer.alternative_email}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Género</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{getGender(customer.gender)}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Grupo(s)</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{groups.length > 0 ? groups : 'Sin grupos'}}</p>
        </div>
      </div>
    </div>
    <div class="column is-4">
      <div class="columns is-multiline is-mobile contenedorInfo">
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Fecha de Nacimiento</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{formatDateTimestamp(customer.birthdate)}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Teléfono</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{customer.phone || 'No ha ingresado su teléfono'}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Código de Referido</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{customer.invitation_code}}</p>
        </div>
        <div class="column is-12 paddingDescription">
          <p class="tagElement">Inicios de sesión</p>
        </div>
        <div class="column is-12 paddingValue">
          <p class="valueElement">{{customer.logins}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../../mixin/validateDate'
export default {
  mixins: [mixin],
  props: {
    customer: {
      type: Object
    },
    addGroup: {
      type: Function,
      default: () => {}
    },
    actionResendEmail: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    customer (newValue) {
      this.groups = this.customer.groups.reduce((accum, group, index) => {
        const coma = index < (this.customer.groups.length - 1) ? ', ' : ''
        accum += `${group.groupName}${coma}`
        return accum
      }, '')
    }
  },
  components: {
    ModalAddUserGroup: () => import('@/components/Customers/ModalAddUserGroup')
  },
  data () {
    return {
      modalAddGroup: false,
      loadingAdd: false,
      groups: ''
    }
  },
  methods: {
    getGender (gender) {
      return !gender
        ? 'No ha selecccionado una opción'
        : gender === 'm'
          ? 'Hombre'
          : 'Mujer'
    },
    showModal () {
      this.modalAddGroup = !this.modalAddGroup
    },
    sendEmail () {
      this.actionResendEmail(this.customer.id)
    }
  }
}
</script>

<style scoped>
  .userImage {
    width: 100px;
    height: 100px;
    background-position: 0;
    border-radius: 50%;
  }
  .tagElement {
    font-weight: bold;
    color: var(--secondary-color);
    font-size: 14px;
  }
  .valueElement {
    font-size: 12px;
    color: #0F0F0F;
    word-wrap: break-word;
  }
  .valueElement.name {
    font-size: 20px;
  }
  .valueElement.userName {
    color: var(--primary-color);
  }
  .calificacion {
    color: var(--primary-color);
  }
  .calificacion .material-icons {
    font-size: 18px;
  }
  .paddingDescription {
    padding-bottom: 0px;
  }
  .paddingValue {
    padding-top: 2px;
  }
</style>
